<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type ContainerSize = "xsmall" | "small" | "regular" | "large" | "full";
export type ContainerVariant = "centered" | "left" | "fullCentered";

type Props = {
    variant?: ContainerVariant;
    size?: ContainerSize;
};

const { variant = undefined, size = "regular" } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "mx-auto w-full px-4 sm:px-6 lg:px-8",
    variants: {
        size: {
            xsmall: "max-w-2xl",
            small: "max-w-3xl",
            regular: "max-w-7xl",
            large: "max-w-screen-2xl",
            full: "max-w-full",
        },
        variant: {
            centered: "",
            left: "ml-0 mr-auto",
            fullCentered:
                "items-center justify-center flex flex-col text-center grow",
        },
    },
};
const { aClass } = installAntlerComponent(
    "container",
    { variant, size },
    classes,
);
</script>

<template>
    <div :class="aClass()">
        <slot />
    </div>
</template>
